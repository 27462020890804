import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Container, Col, Row, Card, Form, Button } from 'react-bootstrap';
import { SearchIcon, LogoutIcon, HomeIcon} from "@heroicons/react/outline";
import Table from "react-bootstrap-table-next";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useAuth  } from "pages/access/useAuth"; 
import Cookies from 'universal-cookie'; 
const cookies = new Cookies();



export default () => { 
	const { 
		endpoint
	} = useAuth();

	const auth = useAuth();
	const [searchterm, setSearchTerm] = useState("");
	const [companies, setCompanies] = useState([])

	const clist = cookies.getAll();
	
	useEffect(() => {
		  axios.get(endpoint+"/company/", {
	  		params: { 
	  			'searchterm': searchterm, 			
	  			}	
			  })  
			  .then(response => {
			  	const theResult = response.data;
			  	setCompanies(theResult);
			  })
		  	.catch(error => console.log(error));	
	},[searchterm]);	
	
  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-lg-0 bg-soft">
        <Container>
{/*			    <Card border="0" className=" mb-0">
			      <Card.Body>
			       	<h5 className="mt-4 mb-2">Search for Company/Operator </h5>
		          <Row>
		            <Col md={6} className="mb-3">
							    <InputGroup>
		                <Form.Control required type="text" placeholder="Enter Company or Operator Name" 
		                		onBlur={(e) => setSearchTerm(e.target.value)} 
		                		onKeyPress={e => e.key === 'Enter' && e.currentTarget.blur()}
 										/>
							      <InputGroup.Text><SearchIcon className="icon icon-xs" /></InputGroup.Text>
							    </InputGroup>
		            </Col>
		          </Row>
						</Card.Body>
			    </Card>
        	<hr className="my-1"></hr>
*/}			    
			    <Card border="0" className=" mb-0">
			    	<Card.Header>
				    	<HomeIcon className="me-1 mb-3" style={{color:"#111827", height:"2.0rem", width:"2.0rem"}} />
				    	<h2 className="d-inline-block text-dark">Ficus Admin Home</h2>
				    	<Button size="sm" className="float-end" onClick={() => auth.signOut() } >Sign Out <LogoutIcon className="me-1" style={{height:"2.0rem", width:"2.0rem"}} /></Button>
			    	</Card.Header>
			      <Card.Body>
			       	<h6 className="mt-1 mb-1">Company Listing - double click to open </h6>
		          <Row>
		            <Col md={12} className="mb-3">
									<ResultTable companies={companies}/>
		            </Col>
		          </Row>
						</Card.Body>
			    </Card>
        </Container>
      </section>
    </main>

  );
};

/* BOOTSTRAP TABLE */

export const ResultTable  = (props) => { 
	const { companies } = props;
	const {
		sectoken,
		user, 
		setUser,
		endpoint,
		adminUser,
		setCurLocation
	} = useAuth();
	
	const auth = useAuth();
	const navigate = useNavigate();
	
	const columns = [
	  { dataField: "companyid", text: "CompanyID", hidden: true },
	  { dataField: "name", text: "Company", width:"175px"}, 
	  { dataField: "firstname", text: "First Name", width: "100"  },
	  { dataField: "lastname", text: "Last Name", width: "100"  },
	  { dataField: "operatoremail", text: "Email", width: "100", hidden: true  }, 
	  { dataField: "personid", text: "personid", width: "100", hidden: true  }, 
	  { dataField: "persontypeid", text: "persontypeid", width: "100", hidden: true  }, 
	  { dataField: "hscontactid", text: "hscontactid", width: "100", hidden: true  }, 
	  { dataField: "hscompanyid", text: "hscompanyid", width: "100", hidden: true  }, 
	  { dataField: "locationlist", text: "locationlist", width: "100", hidden: true  },
	  { dataField: "password", text: "password", width: "100", hidden: true  } 
	];

	const customTotal = (from, to, size) => (
	  <div>
	    Showing {from} to {to} of {size} companies
	  </div>
	);

	const customSizePerPage = (props) => {
	  const { options, currentSizePerPage, onSizePerPageChange } = props;

	  const onPageChange = (e) => {
	    const page = e.target.value;
	    onSizePerPageChange(page);
	  }

	  return (
	    <Row>
	      <Col xs="auto">
	        <Form.Select value={currentSizePerPage} onChange={onPageChange} className="pe-5">
	          {options.map(o => (
	            <option key={o.page} value={o.page}>
	              {o.text}
	            </option>
	          ))}
	        </Form.Select>
	      </Col>
	      <Col xs="auto" className="d-flex align-items-center">
	        companies per page
	      </Col>
	    </Row>
	  );
	};

	const rowEvents = {
	  onDoubleClick: (e, row, rowIndex) => {
			cookies.remove('uid', { path: '/', domain: window.location.hostname  });
			cookies.set('signedin', { path: '/', domain: window.location.hostname  });
{/*
	    try {
		    axios.get(endpoint+"/users/signin/signout.asp")
		    .then(response => {
									const locationList = JSON.parse(row.locationlist);
	    					 	user.uid = row.personid; 
					  			user.firstName = row.firstname; 
					  			user.lastName = row.lastname; 
						  		user.persontype = row.persontypeid; 
					  			user.email = row.operatoremail; 
					  			user.personalemail = row.operatoremail; 
					  			user.companyid = row.companyid;
					  			user.companyname = row.name;
					  			user.hscontactid = row.hscontactid;
					  			user.hscompanyid = row.hscompanyid;
					  			user.locationList = locationList;
					  			user.pwdResetReq = '';
{/
	    					 	setUser({'uid':row.personid, 
					  			'sectoken':sectoken, 
					  			'firstName':row.firstname, 
					  			'lastName':row.lastname, 
						  		'persontype':row.persontypeid, 
					  			'email':row.operatoremail, 
					  			'personalemail':row.operatoremail, 
					  			'companyid':row.companyid,
					  			'companyname':row.name,
					  			'hscontactid':row.hscontactid,
					  			'hscompanyid':row.hscompanyid,
					  			'locationList':locationList,
					  			'pwdResetReq': ''
					  		});
/}
					  		if (locationList.length === 1) { 
					  			setCurLocation(locationList[0]);
					  		}	else {
					  			setCurLocation(null);
					  		}
							  navigate('/dashboard/dashboardoperator.js');;
			    })
						.catch(error => console.log(error)); 
		    } catch {
					return('axios failed')
				}
*/}	    

			setUser(null);		
	    try {
		    axios.get(endpoint+"/users/signin/signout.asp")
		    .then(response => {
		    	const adminacct = (adminUser) ? adminUser : {user: user.uid, email: user.email, firstname: user.firstname, lastname: user.lastname, persontype: user.persontype };
				  auth.signIn({email: row.operatoremail, pwd: row.password, adminUser: adminacct })
					.then(response => {
						if (response === 'active'|| response === 'resetpwd' ) {
//							window.open("/dashboard/dashboardoperator.js", "_blank", "noreferrer")
							  navigate('/dashboard/dashboardoperator.js');
						}
		//				setAcctStatus(response);
					});			  
		    })
					.catch(error => console.log(error)); 
	    } catch {
				return('axios failed')
			}
	  }
	};
	
	
	return (
	  <ToolkitProvider
	    keyField="companyid"
	    search={true}
	    columns={columns}
	    data={companies}
	    wrapperClasses="table-responsive"
	  >
	    {({ baseProps, searchProps }) => (
	      <Paginator.PaginationProvider pagination={
	        Pagination({
	          custom: true,
	          showTotal: true,
	          alwaysShowAllBtns: true,
	          totalSize: companies.length,
	          paginationTotalRenderer: customTotal,
	          sizePerPageRenderer: customSizePerPage
	        })
	      }>
	        {({ paginationProps, paginationTableProps }) => (
	          <Card>
	            <div className="table-responsive py-2">
	              <Card.Header>
	                <Row>
	                  <Col xs={12} md={6} className="py-1">
	                    <Paginator.SizePerPageDropdownStandalone {...paginationProps} />
	                  </Col>
	                  <Col xs={12} md={6} className="d-flex justify-content-md-end py-1">
	                    <Search.SearchBar {...searchProps} />
	                  </Col>
	                </Row>
	              </Card.Header>

	              <Table 
	                {...baseProps} 
	                {...paginationTableProps} 
	                condensed = { true }
	                bodyClasses="border-0" 
	                rowClasses="border-bottom company-select"
	                classes="table-flush dataTable-table"
	                rowEvents = { rowEvents } 
	              />

	              <Card.Footer>
	                <Row>
	                  <Col xs={12} md={6} className="d-flex align-items-center py-1">
	                    <Paginator.PaginationTotalStandalone {...paginationProps} />
	                  </Col>
	                  <Col xs={12} md={6} className="d-flex justify-content-md-end align-items-center mb-0 py-1">
	                    <Paginator.PaginationListStandalone {...paginationProps} />
	                  </Col>
	                </Row>
	              </Card.Footer>
	            </div>
	          </Card>
	        )}
	      </Paginator.PaginationProvider>
	    )}
	  </ToolkitProvider>
	);
}