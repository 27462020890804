import React, { useState, useEffect, useContext, createContext } from "react";

import axios from "axios";
import Cookies from 'universal-cookie'; 


const authContext = createContext();
// Provider component that wraps the app in index.js and makes auth object 
//  available to any child component that calls useAuth(). 
//	the context "authContext" hold uid, name, status, etc. upon credential Validation
//	Credentials are validated either through info in a cookie at launch, through a signin,
//	or possibly through a imitate facility for admin support



const cookies = new Cookies();
const apihost = (window.location.hostname === 'localhost') ? "https://ficusdev.maximtech.com/api" : "https://"+window.location.hostname+"/api";
const proxyhost = (window.location.hostname === 'localhost') ? "https://ficusdev.maximtech.com/include-mt/ajax-fetch-url.asp" : "https://"+window.location.hostname+"/include-mt/ajax-fetch-url.asp";

export function AuthProvider({ children }) {
	const clist = cookies.getAll();
  const urlParams = new URLSearchParams(window.location.search);
  const reset_guid = urlParams.get('reset_guid');

  const auth = useProvideAuth(clist.uid, reset_guid);
  
  return <authContext.Provider value={auth}> 
  	{children} 
  </authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};



// Provider hook that creates auth object and handles state
function useProvideAuth(uid, reset_guid) {
  const [user, setUser] = useState(null);
  const [curLocation, setCurLocation] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const [curStatusGroup, setCurStatusGroup] = useState("Applying");
  const [secToken, setSecToken] = useState(null);
  const [endpoint] = useState(apihost);
  const [proxy] = useState(proxyhost);

  	
			useEffect(() => {
		  const checklogin = async (uid, reset_guid) => {
		    try {
			    await axios.get(endpoint+"/users/signin/default.asp", {
			  		params: { 'uid': uid, 'reset_guid': reset_guid }	
		  		}, {
				    headers: {
				      'Content-Type': 'application/x-www-form-urlencoded'
			    	}
		  	}) 		  
				  .then(response => {
				  	const theResult = response.data[0];
				  	if (theResult) {
				  		setSecToken(theResult.sectoken ? theResult.sectoken : null);
					  	if ( theResult.status === 'Active' ) {
					  		switch (theResult.persontypeid) {
					  		case 1000:
					  			
					  		break;
					  		default:
						  		const locationList = JSON.parse(theResult.locationlist);
						  		setUser({'uid':theResult.personid, 
						  			'sectoken':theResult.sectoken, 
						  			'persontype':theResult.persontypeid, 
						  			'firstName':theResult.firstname, 
						  			'lastName':theResult.lastname, 
						  			'email':theResult.email, 
						  			'personalemail':theResult.personalemail, 
						  			'companyid':theResult.companyid,
						  			'companyname':theResult.companyname,
						  			'hscontactid':theResult.hscontactid,
						  			'hscompanyid':theResult.hscompanyid,
						  			'locationList':locationList,
						  			'pwdResetReq': theResult.pswd_reset_required_ind
						  		});
						  		if (locationList && locationList.length === 1) { 
						  			setCurLocation(locationList[0]);
						  		}	else {
						  			setCurLocation(null);
						  		}
						  		break;
						  	}
						  	
/*
								cookies.set('uid', theResult.personid, { path: '/' });
						  	cookies.set('signedin', 'yes', { path: '/' })
*/
					  		if (theResult.pswd_reset_required_ind === 'Yes') {
					  			return('resetpwd')
								}
						  	return('active')
						  } else {
						  	return('inactive')
						  }  		
				  	} else {
			      	return('invalid')
				  	}
				  })
					.catch(error => console.log(error)); 
						return('failed')
		    } catch {
					return('axios failed')
		    }
		  }; 

		  checklogin(uid, reset_guid);
		  
// eslint-disable-next-line react-hooks/exhaustive-deps
		},[]);  

  async function signIn(props) {
					const { adminUser } = props;
			    var foo = await axios.get(endpoint+"/users/signin/default.asp", {
			  		params: { 'email': props.email, 'pwd':props.pwd }	
		  		}, {
				    headers: {
				      'Content-Type': 'application/x-www-form-urlencoded'
			    	}
		  		}) 		  
				  .then(response => {
				  	const theResult = response.data[0];
				  	if (theResult) {
					  	if ( theResult.status === 'Active' ) {
					  		const locationList = JSON.parse(theResult.locationlist);
					  		setAdminUser(((theResult.persontypeid === '100') || props.adminUser )  ? adminUser : '');
					  		setUser({'uid':theResult.personid, 
					  			'sectoken':theResult.sectoken, 
					  			'firstName':theResult.firstname, 
					  			'lastName':theResult.lastname, 
						  		'persontype':theResult.persontypeid, 
					  			'email':theResult.email, 
					  			'personalemail':theResult.personalemail, 
					  			'companyid':theResult.companyid,
					  			'companyname':theResult.companyname,
					  			'hscontactid':theResult.hscontactid,
					  			'hscompanyid':theResult.hscompanyid,
					  			'locationList':locationList,
					  			'pwdResetReq': theResult.pswd_reset_required_ind
					  		});
					  		if (locationList.length === 1) { 
					  			setCurLocation(locationList[0]);
					  		}	else {
					  			setCurLocation(null);
					  		}
/*
						  	cookies.set('uid', theResult.personid, { path: '/' });
						  	cookies.set('signedin', 'yes', { path: '/' })
*/
					  		if (theResult.pswd_reset_required_ind === 'Yes') {
					  			return('resetpwd')
								}
						  	return('active')
						  } else {
						  	return('invalid')
						  }  		
				  	} else {
			      	return('invalid')
				  	}
				  })
					.catch(error => console.log(error)); 
					
					return(foo)

  };

  const signOut = () => {
		cookies.remove('uid', { path: '/', domain: window.location.hostname  });
		cookies.set('signedin', { path: '/', domain: window.location.hostname  });
		setUser(null);		
    try {
	    axios.get(endpoint+"/users/signin/signout.asp")
	    .then(response => {
	    	window.location.href = '/';
	    })
				.catch(error => console.log(error)); 
    } catch {
			return('axios failed')
		}
						
		return
		
  };

  // Return the user object and auth methods
  return ( {user, setUser, curLocation, setCurLocation, curStatusGroup, setCurStatusGroup, secToken, endpoint, proxy, signIn, signOut, adminUser} );
}