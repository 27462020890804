import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Card, Table, Modal, Button, OverlayTrigger, Tooltip, Dropdown, ButtonGroup} from 'react-bootstrap';
import { ChatIcon, MailIcon, CheckIcon, XIcon, AcademicCapIcon, InboxInIcon, DocumentDuplicateIcon, CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import {ChevronDownIcon} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import { myRoutes } from "routes";

import {EVModal, LocationModal} from "components/FicusModals.js";


import { useAuth } from "pages/access/useAuth"; 

const storyIcons = {
		Inquiring: InboxInIcon,
		Applying: DocumentDuplicateIcon,
		Enrolled: AcademicCapIcon
};

const statusIconMap = { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon };


function VerificationIcon (props) {
	const {ev} = props;
	var dotcolor = 'red';
	var statsicon = '';
	
	switch (ev) {
		case 'Unverified':
			dotcolor = '#ffde24';
			statsicon = 'ExclamationCircleIcon'
			break;
		case 'Eligible':
			dotcolor ='#005c00';
			statsicon = 'CheckCircleIcon'
			break;
		default:
			dotcolor = '#e32f2f';
			statsicon = 'XCircleIcon'
			break;			
	}
	
	const StatusIcon = statusIconMap[statsicon]; 

	return (
			<StatusIcon className="icon icon-xxs" style={{strokeWidth:3, color:dotcolor}}></StatusIcon>	
	)

}


const FafsaTMAModal  = (props) => {
	const {showme, studentid, onClose } = props;
	const { 
		endpoint
	} = useAuth();
	 
	const [student, setStudent] = useState(true);
	const [getData, setGetData] = useState(true);

	const onShowHandler = (studentid) => {
			setGetData(false)
		  axios.get(endpoint+"/student/default.asp", {
				  		params: { 
				  			'id':studentid, 
				  		}	
			  })  
			  .then(response => {
			  	const theResult = response.data[0];
			  	setStudent(theResult);
			  })
		  	.catch(error => console.log(error));
	}
	
	function closeMe() {
		onClose();
		setGetData(true);
	}
		
	return (
  <Modal centered show={showme} onHide={() => {}} onShow ={showme && getData && onShowHandler(studentid)}>
    <Modal.Header style = {{alignItems: "normal"}}>
      <Modal.Title>
      	<div className="float-start">
	      	<h4 className="mb-0">{student && student.FirstName} {student && student.LastName}</h4>
	      	<h6><i>{student.LocationName}</i></h6>
	      </div>
	      <div className="ms-4 float-end text-right">
					<Button variant="default" href={`sms:${student.Mobile}?&body=Education Program:`} size="sm" target="_blank">
		      	<ChatIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Message</div>
					</Button>
					<Button variant="default" href={`mailto:${student.Email}?&body=&subject=Education Program`} target="_blank">
		      	<MailIcon className="icon icon-xxs" color="rgba(115,164,11,100%)"/>
						<div style={{fontSize: "0.7rem"}}>Email</div>
					</Button>
	      </div>

      </Modal.Title>
      <Button variant="close" aria-label="Close" onClick={closeMe} />
  	</Modal.Header>
      <Modal.Body>
      <div className="small studentstatus">
      	<h5 className="mb-0 "><b>Phase: </b>{student.StudentSummaryStatus}</h5>
      	<h6><b>Status: </b>{student.StudentStatus} {((student.StatusReason === '') ? '': ' ('+student.StatusReason+')')} on {student.AssignedDate}</h6>
				<hr/>
				<h6 className="mb-2">Please be aware that FAFSA and Transcript processing can take a few days to a few weeks to complete after requests are submitted by Team Members.</h6>
      	<div className="mb-3 ms-1 ">
      		{((student.FAFSA === 'Completed') ? <CheckIcon color="green" />:<XIcon color="red"/>)}
      		<b>FAFSA Complete</b>
      		<div>
      			All students are required to complete the FAFSA <small>(Free Application for Federal Student Aid)</small> to recieve financial aid under the Ficus Employee Sponsored Higher Education
      			 (ESHE) program. Team Members submit the FAFSA directly to the U.S. Department of
      			Education(ED) for processing, after which the ED forwards the application to the Financial Aid department.      
      		</div>
      	</div>       	
      	<div className="mb-3 ms-1 ">
      		{((student.TMA === 'Completed') ? <CheckIcon color="green" />:<XIcon color="red"/>)}
      		<b>Transcripts Complete</b>
      		<div>
      			The Transcript request process varies across educational institutions, but generally a Team Member initiates 
      			the request to schools or universities where they have prior enrollment. 
      			Depending on the policy of the transcript provider, the transcript may be sent to the Team Member or directly to the university&apos;s Admissions Department.       
      		</div>
      	</div>
      	<div>
      		<b>A Representative of the university will assist students applying for admission with the FAFSA and Transcript request processes.</b> 
      	</div>      	      	   
      </div>
      
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={closeMe}>
      	Close
      </Button>
    </Modal.Footer>
  </Modal>  
  )	
};


export const StudentTable = (props) => {
	const { 
		user, 
		curLocation,
		endpoint
	} = useAuth(); 	
	const { sortorder, ficusstatus, showphase, showcurrentonly, setCount } = props;
	
	const showPhase = (showphase === true ) ? 'table-cell' : 'none';
	const showState = (showcurrentonly === 'true' ) ? 'none' : 'table-cell';
  const navigate = useNavigate();
	const [students, setStudents] = useState([]); 
	const [rerender, setRerender] = useState(false); /* This funky bit forces rerender of the table when employee status is updated */

	const [studentid, setStudentid] = useState([]);
	const [showEVModal, setShowEVModal] = useState(false);
	const [showFafsaTMAModal, setShowFafsaTMAModal] = useState(false);
	const [showLocationModal, setShowLocationModal] = useState(false);

	useEffect(() => {
	  axios.get(endpoint+"/student/", {
  		params: { 
  			'companyid': user.companyid, 
  			'locationid': curLocation && curLocation.locationid,
  			'orderby': sortorder ? sortorder : '',
  			'ficusstatus': ficusstatus ? ficusstatus : '',
  			'showcurrentonly': showcurrentonly ? showcurrentonly : ''   			
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStudents(theResult);
		  	setCount && setCount(theResult.length);
		  })
	  	.catch(error => console.log(error));	
	},[user, curLocation, ficusstatus, rerender, endpoint, setCount, sortorder, showcurrentonly]);

	function StatusCell(props) {
		const {status, reason} = props
		return (
			<>
				<div>{status}</div>
				<div style={{marginTop: "-5px"}}>
      	{(() => {
					if (reason !== '') {
						return ( <small >{((reason === '') ? '': ' ('+reason+')')}</small> )
					} 
      	})()}
				</div>
			</>		
		)
		
	}
	
	
	function EmpVerifiedCell (props) {
		const {ev} = props;
		return (
			<OverlayTrigger key="xverify" overlay={<Tooltip id="xverify" className="m-0 red" >Employment Status: {ev}</Tooltip>}>
				<span><VerificationIcon ev={ev}></VerificationIcon></span>
			</OverlayTrigger>		
		)				
	}

	function FafsaTMACell (props) {
		const {status} = props;		

		const dotcolor = (status === 'Completed') ? '#005c00': '#e32f2f';
		const StatusIcon = (status === 'Completed') ? statusIconMap['CheckCircleIcon'] : statusIconMap['XCircleIcon'];
		return (
			<OverlayTrigger key="fafsa" overlay={<Tooltip id="fafsa" className="m-0 red" >{(status) ? status : 'Not Reported'}</Tooltip>}>
				<StatusIcon className="icon icon-xxs" style={{strokeWidth:3, color:dotcolor}}></StatusIcon>
			</OverlayTrigger>		
		)				
	}


  const TableRow = (props) => {
		const { PersonID, AssignedDate, FirstName, LastName, LocationName, EmploymentVerified, StudentStatus, StatusReason, StudentSummaryStatus, TMA, FAFSA, IsCurrent, StartDate } = props;

    return (
      <tr >
{/*
			  <td className="border-0 px-0" style={{ width: '0.8rem' }}>
					<OverlayTrigger key="contact" overlay={<Tooltip id="contact" className="m-0 red" >Contact Support Regarding Student</Tooltip>}>
			      <QuestionMarkCircleIcon className="icon icon-xxs" color="rgb(0, 89, 179)" style={{height:"0.8rem"}}
			      	onClick={() => {navigate(myRoutes.Contact.path, { state: {PersonID} }); }}/>
			  </td>
*/}
        <td className="border-0" style={{ width: 'auto',  
        	whiteSpace: 'nowrap',
    			overflow: 'hidden',
    			textOverflow: 'ellipsis',
    			maxWidth: '18ch',
    			color:'#0059B3', 
    			fontWeight:500
 					}} >
						<OverlayTrigger key="contact" overlay={<Tooltip id="contact" className="m-0" >Contact Support Regarding Student</Tooltip>}>
							<span onClick={() => {navigate(myRoutes.Contact.path, { state: {PersonID} }); }}>{FirstName+' '+LastName}</span>
			      </OverlayTrigger >
 					</td>
				  <td className="border-0 px-0" style={{ width: 'auto', display: showState }}>
				  	<span>{IsCurrent}</span>
				  </td>
          {(user.locationList.length > 1) ? (
        		<td className="border-0" style={{color:'#0059B3', fontWeight:500}}>
							<OverlayTrigger key="location" overlay={<Tooltip id="contact" className="m-0 red" >Change Location</Tooltip>}>
					      <span  onClick={() => {setStudentid(PersonID); setShowLocationModal(true)}} >{LocationName}</span>
					    </OverlayTrigger >
					  </td>
			    ) :
			    (
        		<td className="border-0" >
				      <span>{LocationName}</span>
				    </td>
				    )
			  	}
	        <td className="border-0" style={{ display: showPhase }}>
	        	<span>{StudentSummaryStatus}</span>
	        </td>
        {(ficusstatus === "Inquiring") ? 
        	(
        		<>
        			<td className="border-0" style={{ width: 'auto', fontSize:'0.6rem', paddingTop:'0.35rem' }} >{moment(AssignedDate).format('L')}</td>
			        <td className="border-0 statusindicator" style={{ width: 'auto' }} onClick={() => {setStudentid(PersonID); setShowEVModal(true) }}>
								<EmpVerifiedCell ev={EmploymentVerified} />
			        </td>
		        </>
        	) : (
        		<>
			        <td className="border-0" style={{ width: '1rem' }}>
			        	<StatusCell status={StudentStatus} reason={StatusReason} tma={TMA} fafsa={FAFSA} />
			        </td>
        			<td className="border-0" style={{ width: 'auto', fontSize:'0.6rem', paddingTop:'0.35rem' }} >{AssignedDate ? moment(new Date(AssignedDate)).format('L') : ''}</td>
        			<td className="border-0" style={{ width: 'auto', fontSize:'0.6rem', paddingTop:'0.35rem' }} >{StartDate ? moment(StartDate).format('L') : 'N/A'}</td>
			        <td className="border-0 statusindicator" style={{ width: 'auto' }}  onClick={() => {setStudentid(PersonID); setShowEVModal(true) }}>
								<EmpVerifiedCell ev={EmploymentVerified}/>
			        </td>
			        <td className="border-0 statusindicator" style={{ width: 'auto' }} onClick={() => {setStudentid(PersonID); setShowFafsaTMAModal(true)} }>
								<FafsaTMACell status={FAFSA}  />
			        </td>
			        <td className="border-0 statusindicator" style={{ width: 'auto' }} onClick={() => {setStudentid(PersonID); setShowFafsaTMAModal(true)} }>
								<FafsaTMACell status={TMA}  />
			        </td>
		        </>
        	)
        }
      </tr>
    );
  };

  return (
  <>
	     <Table responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
	      <thead className="thead-light">
	        <tr style={{height:'3rem'}} >
{/*	          <th className="border-0" style={{ width: 'auto' }}>  </th>  */}
	          <th className="border-0" style={{ width: 'auto' }}>Team Member</th>
	          {!(showcurrentonly === 'true' ) &&
	          	
							<OverlayTrigger key="statelhead" style={{width:'auto'}} overlay={<Tooltip id="iscurrent" className="m-0 red" >Was Team Member Active in Last 180 Days?</Tooltip>}>
		          	<th className="border-0" style={{ width: 'auto' }}><div style={{width:'auto' }}>State</div></th>
		          </OverlayTrigger>
	           
	         	}
			      <th className="border-0" style={{ width: 'auto' }}>Location</th>
			      <th className="border-0" style={{ width: 'auto', display: showPhase }}>Phase</th>
		        {(ficusstatus === "Inquiring") ? 
		        	(
		        		<>		        		
									<th className="border-0" style={{ width: 'auto' }}>Last<br></br>Update</th>
									<OverlayTrigger key="emplhead" overlay={<Tooltip id="emplhead" className="m-0 red" >Has Team Member Employment Been Verfied?</Tooltip>}>
				          	<th className="border-0" style={{ width: 'auto' }}><div style={{transform:'rotate(-90deg)', width:'0.5rem'}}>EMPL</div></th>
				          </OverlayTrigger>
		        		</>
		        	) : (
		        		<>		        		
			      			<th className="border-0" style={{ width: 'auto' }}>Status</th>
									<th className="border-0" style={{ width: 'auto' }}>Last<br></br>Update</th>
									<OverlayTrigger key="start" overlay={<Tooltip id="start" className="m-0 red" >Beginning Date of Coursework</Tooltip>}>
				          	<th className="border-0" style={{ width: 'auto' }}><div style={{width:"auto"}}>Start</div></th>
				          </OverlayTrigger>
									<OverlayTrigger key="emplhead" overlay={<Tooltip id="emplhead" className="m-0 red" >Has Team Member Employment Been Verfied?</Tooltip>}>
				          	<th className="border-0" style={{ width: 'auto' }}><div style={{transform:'rotate(-90deg)', width:'0.5rem'}}>EMPL</div></th>
				          </OverlayTrigger>
									<OverlayTrigger key="fafsa" overlay={<Tooltip id="fafsa" className="m-0 red" >Has FAFSA (Free Application for Federal Student Aid) been processed?</Tooltip>}>
				          	<th className="border-0" style={{ width: 'auto' }}><div style={{transform:'rotate(-90deg)', width:'0.5rem'}}>FAFSA</div></th>
				          </OverlayTrigger>
									<OverlayTrigger key="trx" overlay={<Tooltip id="trx" className="m-0 red" >Have student transcripts been recieved?</Tooltip>}>
				          	<th className="border-0" style={{ width: 'auto' }}><div style={{transform:'rotate(-90deg)', width:'0.5rem'}}>TRX</div></th>
				          </OverlayTrigger>
		        		</>
		          )
		        }
	        </tr>
	      </thead>
	      <tbody>
	        {students.map(c => <TableRow key={`persons-${c.PersonID}`} {...c} />)}        
	      </tbody>
	     </Table>
		<EVModal 
			showme={showEVModal} 
			studentid={studentid} 
			onClose={() => {setShowEVModal(false); setStudents([]); setRerender(!rerender)  }} /> 
		<FafsaTMAModal 
			showme={showFafsaTMAModal} 
			studentid={studentid} 
			onClose={() => {setShowFafsaTMAModal(false); setStudents([]); setRerender(!rerender)  }} /> 
		<LocationModal 
			showme={showLocationModal} 
			studentid={studentid} 
			onClose={() => {setShowLocationModal(false); setStudents([]); setRerender(!rerender)  }} /> 
	</>
  );
	
	
}





export const StudentListCard = (props) => {
	const { sortorder, ficusstatus, showcurrentonly } = props;
	const StoryCardIcon = storyIcons[ficusstatus];
	const [studentCount, setStudentCount] = useState(''); 
  return (
  <>
		<Card className="mx-n2 " style={{boxShadow: "3px 3px 3px lightgray"}}>
			<Card.Header className="py-1 inquiring">
				<div className="ms-n2 mb-0 " >
					<StoryCardIcon className="icon icon-sm me-1" style={{strokeWidth:1, verticalAlign:'bottom'}} />
					{studentCount} Team Members {ficusstatus}
				</div>
			</Card.Header> 
			<Card.Body className="p-1">
	     <StudentTable sortorder={sortorder} ficusstatus={ficusstatus} setCount={setStudentCount} showphase={false} showcurrentonly={showcurrentonly}/>
			</Card.Body>
		</Card>
	</>
  );
};

export const GraduatesListCard = (props) => {
	const { 
		user,
		endpoint
	} = useAuth(); 	

	const [studentCount, setStudentCount] = useState(''); 
	const [students, setStudents] = useState([]); 

	const ShowStudents = (userlist) => {
		
	  const TableRow = (props) => {
	    const { FirstName, LastName, LocationName, EduProviderName, AssignedDate  } = props;

	    return (
	      <tr>
	        <td className="border-0" style={{ width: 'auto' }}>{FirstName+' '+LastName}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{EduProviderName}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{LocationName}</td>
	        <td className="border-0" style={{ width: 'auto' }}>{AssignedDate}</td>
	      </tr>
	    );
	  };

	  return ( 
	  <>
	     <Table responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
	      <thead className="thead-light">
	        <tr>
	          <th className="border-0" style={{ width: 'auto' }}>Team Member</th>
	          <th className="border-0" style={{ width: 'auto' }}>Institution</th>
	          <th className="border-0" style={{ width: 'auto' }}>Location</th>
	          <th className="border-0" style={{ width: 'auto' }}>Date</th>
	        </tr>
	      </thead>
	      <tbody>
	        {students.map(ul => <TableRow key={`persons-${ul.PersonID}`} {...ul} />)}        
	      </tbody>
	     </Table>

			</>
	  );
	}  
	
	useEffect(() => {
	  axios.get(endpoint+"/student/", {
  		params: { 
  			'companyid': user.companyid, 
  			'orderby': 'lastname',
  			'ficusstatus': 'Graduated',
  			'showcurrentonly': 'false'   			
  			}	
		  })  
		  .then(response => {
		  	const theResult = response.data;
		  	setStudents(theResult);
		  	setStudentCount && setStudentCount(theResult.length);
		  })
	  	.catch(error => console.log(error));	
	},[]);

  return (
  <>
		<Card className="mx-n2 " style={{boxShadow: "3px 3px 3px lightgray", display:(studentCount > 0) ? 'block' : 'none'}} >
			<Card.Header className="py-1 inquiring">
				<div className="ms-n2 mb-0 " >
					<AcademicCapIcon className="icon icon-sm me-1" style={{strokeWidth:1, verticalAlign:'bottom'}} />
					Team Member Graduates
				</div>
			</Card.Header> 
			<Card.Body className="p-1">
	     <ShowStudents  />
			</Card.Body>
		</Card>
	</>
  );
};




export const HubspotContacts = () => {
	const { 
		proxy
	} = useAuth(); 	
	
	
	const { secToken } = useAuth(); 	
	const [hsContacts, sethsContacts] = useState([]);

	useEffect(() => {
		const hsurl="https://api.hubapi.com/crm/v3/objects/contacts";  	
		axios.get(proxy, {
				params: { 'sectoken': secToken, 
					'url':hsurl, 
					'status_only_ind':'No', 
					'content_type':'application/json',
					'authorization_header': 'Bearer pat-na1-5e11e810-64bd-4933-867f-846d6c66e09b'
				}	
			})  
		  .then(response => {
				const theResult = response.data.results;	        
	  		sethsContacts(theResult);
		  })
	  	.catch(error => console.log(error));	
	},);


/*
	useEffect(() => {
		hubspotClient.crm.contacts.basicApi
	    .getPage(20)
	    .then(response => {
				const theResult = response.results;	        
				console.log(theResult)
	  		sethsContacts(theResult);
	    }) 
	    .catch((err) => {
	        console.error(err)
	    })
	}, []);
*/
	
  const TableRow = (props) => {

    return (
      <tr>
        <td className="border-0" style={{ width: 'auto' }}>{props.id}</td>
        <td className="border-0" style={{ width: 'auto' }}>{props.properties.firstname}</td>
        <td className="border-0" style={{ width: 'auto' }}>{props.properties.lastname}</td>
        <td className="border-0" style={{ width: 'auto' }}>{props.properties.email}</td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: 'auto' }}>Record ID</th>
              <th className="border-0" style={{ width: 'auto' }}>First Name</th>
              <th className="border-0" style={{ width: 'auto' }}>Last Name</th>
              <th className="border-0" style={{ width: 'auto' }}>Email</th>
            </tr>
          </thead>
          <tbody>
            {
            	hsContacts.map(c => <TableRow key={`hsContacts-${c.id}`} {...c} />)
            }        
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

/* Employment Verification Table */

export const VerificationTable = (studentlist, setstudentlist, rerender, setrerender) => {
	const { 
		endpoint
	} = useAuth(); 	

	const [showVerificationConfirm, setShowVerificationConfirm] = useState(false);	
	const [newEmploymentStatus, setNewEmploymentStatus] = useState();	
	const [personName, setPersonName] = useState('');	
	const [personId, setPersonId] = useState('');	

	const arrayFindObjectByProp = (arr, prop, val) => {
	  return arr.find((obj) => obj[prop] === val);
	};

	function showVerificationModal(personid, personname, newevstatus) {
		setNewEmploymentStatus(newevstatus);
		setPersonName(personname);
		setPersonId(personid);	
		setShowVerificationConfirm(true);	
	};
	
	function setVerified(personid, newEVStatus) {		
		
	  axios.get(endpoint+"/student/verify.asp", {
	  		params: { 
	  			'id':personid, 
	  			'verified': newEVStatus
	  		}	
		  })  
		  .then(response => {
  			const student = studentlist && arrayFindObjectByProp(studentlist, "PersonID", personid);
  			student.EmploymentVerified = newEVStatus;
  			student.EmploymentVerifiedDate = moment().format("MM-DD-YYYY HH:mm:ss");
  			let newstudentlist = [...studentlist]
  			setstudentlist(newstudentlist)			  	
		  })
	  	.catch(error => console.log(error));    

  }

  const TableRow = (props) => {
    const { PersonID, FirstName, LastName, StudentSummaryStatus, EmploymentVerified, EmploymentVerifiedDate  } = props;	
	
    return (
      <tr>
        <td className="border-0" style={{ width: 'auto' }}>	         
        	{FirstName+' '+LastName} (<span>{StudentSummaryStatus}</span>)
        </td>
        <td className="border-0" style={{ width: 'auto' }}>
						<div>
							<VerificationIcon ev={EmploymentVerified}></VerificationIcon>
							<div id={'stattxt'+PersonID } style={{ marginLeft: '0.2rem', display:'inline-block', verticalAlign:'text-top' }}>{EmploymentVerified}</div>
						</div>         		
        </td>
        <td className="border-0">
        	<span>
      		<button type="button" className="btn-xs btn verify Eligible" id={"pid-"+PersonID} onClick={() => {setVerified(PersonID,'Eligible')}}>
						<CheckCircleIcon className="icon icon-xxs" style={{color:'white'}}></CheckCircleIcon> Eligible
      		</button>
						 <Dropdown className="d-inline-block" as={ButtonGroup} autoClose={false}
						         onClick={() => {
         							const vtable = document.getElementById('vtable').parentElement;
						          getComputedStyle(vtable).overflowX === 'scroll'
						            ? (vtable.style.overflowX = 'visible')
						            : (vtable.style.overflowX = 'scroll');
						        }}
						        
						        title="Dropdown"
						        id="basic-nav-dropdown"
      			 >
					    <Dropdown.Toggle split className="btn-xs btn verify Terminated" >
					      <XCircleIcon className="icon icon-xxs" style={{color:'white'}}></XCircleIcon> Not Eligible <ChevronDownIcon className="icon icon-xs" />
					    </Dropdown.Toggle>

					    <Dropdown.Menu style={{width:'100px', minWidth:'auto', paddingTop:'0.2rem', paddingBottom:'0.2rem', paddingLeft:'0.3rem', transform:'translate3d(0px, 21px, 0px)', marginLeft:'0.5rem'}}>
					      <Dropdown.Item style={{fontSize:'0.7rem', padding:'0.1rem', fontWeight:'500'}} onClick={() => {showVerificationModal(PersonID, FirstName+' '+LastName, 'Terminated')}}>Terminated</Dropdown.Item>
					      <Dropdown.Item style={{fontSize:'0.7rem', padding:'0.1rem', fontWeight:'500'}} onClick={() => {showVerificationModal(PersonID, FirstName+' '+LastName,'Not Eligible')}}>Not Eligible</Dropdown.Item>
					    </Dropdown.Menu>
					  </Dropdown>
        	</span>
        </td>
        <td id={'statdate'+PersonID } className="border-0" style={{ width: 'auto' }}>{(EmploymentVerifiedDate) ? moment(new Date(EmploymentVerifiedDate)).format('L') : ''}</td>
      </tr>
    );
  };

	
  return ( 
  	<div className="xoverflow">
			<Table id='vtable' responsive className="dashboard table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
				<thead className="thead-light">
				  <tr>
				    <th className="border-0" style={{ width: 'auto' }}>Team Member</th>
				    <th className="border-0" style={{ width: 'auto' }}>Eligibility</th>
				    <th className="border-0" style={{ width: 'auto' }}></th>
				    <th className="border-0" style={{ width: 'auto' }}>Updated</th>
				  </tr>
				</thead>
				<tbody>
				  {studentlist.map(c => <TableRow key={`persons-${c.PersonID}`} {...c} />) }
				</tbody>
			</Table>
			
	    <Modal as={Modal.Dialog} centered show={showVerificationConfirm} >
		    <Modal.Header>
		      <Modal.Title>
		      	<h5>Changing {personName} to {newEmploymentStatus} Status</h5>
		      </Modal.Title>
		      <Button variant="close" aria-label="Close" onClick={() => {setShowVerificationConfirm(false)}}/>
		  	</Modal.Header>
	      <Modal.Body style={{fontSize:'0.9rem'}}>
	      	
	      	<div style={{color:'#c72727', fontWeight:'bold'}}>This Team Member will 
	      	be removed from the main Operator Dashboard when employment status is {newEmploymentStatus}.</div>   
	      	Their record may still be accessed via the Team Members menu item.      	
	      </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {setVerified(personId, newEmploymentStatus); setShowVerificationConfirm(false)}}>
            Confirm Status Change
          </Button>
          <Button variant="info" onClick={() => {setShowVerificationConfirm(false)}}>
            Cancel
          </Button>
        </Modal.Footer>
			</Modal>
		</div>
  );


}  