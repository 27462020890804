
import React, { useState } from "react";
import moment from "moment-timezone";
import { CurrencyDollarIcon, QuestionMarkCircleIcon, ChatIcon, UserCircleIcon } from "@heroicons/react/solid";
import { PaperAirplaneIcon, MenuIcon, ChartSquareBarIcon, UserGroupIcon, MailIcon, LogoutIcon, HomeIcon } from "@heroicons/react/outline";
import { Row, Col, Nav, Button, Navbar, Dropdown, Container, ListGroup, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { myRoutes } from "routes";
import { userNotifications } from "data/notifications";
import { LocationDropDownWidget } from "components/FicusWidgets.js";
import { PasswordForm } from "components/FicusForms";
import { useAuth } from "pages/access/useAuth"; 
import Cookies from 'universal-cookie'; 
import OrganizationLogo from "assets/img/logos/5000_Futures.jpg"; 

const cookies = new Cookies({ path: '/', domain: window.location.hostname  });
const OrganizationName = "5000 Futures"

export const AdminBar = (props) => { 
	const { adminUser } = props;
	const {
		setUser,
		endpoint
	} = useAuth();
	const auth = useAuth();
	const navigate = useNavigate();

	const adminReload = () => {
		cookies.remove('uid', { path: '/', domain: window.location.hostname  });
		cookies.set('adminuser', adminUser );
		
		setUser(null);		
    try {
//	    axios.get(endpoint+"/users/signin/signout.asp")
//	    .then(response => {
			  auth.signIn({email: adminUser.email, pwd: adminUser.user, adminUser: adminUser})
				.then(response => {
					if (response === 'active'|| response === 'resetpwd' ) {
						  navigate('/access/FicusAdminHome.js');
					}
				});			  
//	    })
//				.catch(error => console.log(error)); 
    } catch {
			return('axios failed')
		}
  };


	return (
	(adminUser) ? 
		<div className="text-left px-3" style={{backgroundColor: "#e00101", color:"white",fontSize:"0.9rem"}}>
			Super Admin Privileges via Account - { adminUser.email }
			  <a className="text-start" onClick={adminReload} style={{float: "right", color: "white", textDecoration:"underline"}}>
			    Admin Home <HomeIcon className="ms-1" style={{height:"1.0rem", width:"1.0rem"}} />
			  </a>
		</div>  : ''
	)
}

export default (props) => {
	const { 
		user,
		adminUser
	} = useAuth();  
	
  const [notifications, setNotifications] = useState(userNotifications);
  const allNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const bellIconClasses = !allNotificationsRead ? "unread" : "";

	const [showResetPasswordModal, setShowResetPasswordModal] = useState((user.pwdResetReq === 'Yes'));
	const [showSignOut, setShowSignOut] = useState(false);
	const handleClose = () => setShowSignOut(false);

	const auth = useAuth();

	const navigate = useNavigate();

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 400);
  };

  const Notification = (props) => {
    const { link, sender, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";
    const receiveTime = (time) ? moment(time).fromNow() : '';

    return (
      <ListGroup.Item action href={link} className="list-group-item-action border-bottom">
        <Row className="align-items-center">
          <Col className="ps-0 ms-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>
                  {receiveTime}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };
  
  const SignOutConfirm  = (props) => {
  	return (
	  <Modal centered show={showSignOut} onHide={handleClose}>
	    <Modal.Header>
	      <Modal.Title>
	        <p className="mb-0 pe-3 pe-md-0">
	          Really Sign Out?
	        </p>
	      </Modal.Title>
	      <Button variant="close" aria-label="Close" onClick={handleClose} />
	    </Modal.Header>
	    <Modal.Body>
	        <p>Please confirm Ficus Education Dashboard sign out</p>
	    </Modal.Body>
	    <Modal.Footer>
	      <Button variant="danger" size="sm" onClick={() => auth.signOut() }>
	      	<LogoutIcon className="icon icon-xs me-1" />Sign Out
	      </Button>
	    </Modal.Footer>
	  </Modal>  
	  );	
  };

	let currentRoute = useLocation()

	const ResetPasswordModal = () => {
		return (
	  <Modal centered show={showResetPasswordModal} >
	    <Modal.Header style = {{alignItems: "normal"}}>
      	<Button variant="close" aria-label="Close" onClick={() => setShowResetPasswordModal(false)} />
      </Modal.Header>
	    <Modal.Body>
	        <PasswordForm onClose={() => {setShowResetPasswordModal(false) }}/>
	    </Modal.Body>
	  </Modal>  
	  );	
	}

  return (
		<>
    <Navbar expand variant="light" className="navbar-top navbar-ficus-dashboard pt-0 ps-0 pe-2 pb-0 me-n4 ms-n3"> 
      <Container className="d-block px-1">
				<AdminBar adminUser={adminUser} />
        <div className="d-flex justify-content-between pt-1" style={{backgroundColor:"#d1d5db"}}>        	
          <div className="d-flex align-items-center">
{/*
	           <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
*/}
            <Nav className=""> 
            <Dropdown as={Nav.Item} className="mb-md-2 ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="py-0 px-0">
                  <div className=" me-2 align-items-center d-lg-block">   
				           <MenuIcon className=" icon icon-xxs ms-2 d-inline-block" style={{strokeWidth:"1.3", height:"1.5rem"}} />  
                  </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/dashboard/dashboardoperator.js', {replace: true})}>                 
                  <ChartSquareBarIcon className="dropdown-icon text-gray-400 me-2" /> Dashboard
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/profile', {replace: true})}>                 
                  <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/myaccount', {replace: true})}>                 
                  <CurrencyDollarIcon className="dropdown-icon text-gray-400 me-2" /> My Account
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/share', {replace: true})}>
                  <PaperAirplaneIcon className="dropdown-icon text-gray-400 me-2" style={{rotate: "65deg", marginTop: "-5px"}}/> Share Inquiry Form
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/students.js', {replace: true})}>
                  <UserGroupIcon className="dropdown-icon text-gray-400 me-2" /> Team Members
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/notifications', {replace: true})}>
                  <MailIcon className="dropdown-icon text-gray-400 me-2" /> Notifications
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/FAQ', {replace: true})}>
                  <QuestionMarkCircleIcon className="dropdown-icon text-gray-400 me-2" /> FAQ
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" onClick={()=> navigate('/Contact', {replace: true})}>
                  <ChatIcon className="dropdown-icon text-gray-400 me-2" /> Contact Us
                </Dropdown.Item>

                <Dropdown.Divider as="div" className="my-1" />

                <Dropdown.Item className="d-flex align-items-center" onClick={ () => setShowSignOut(true)} >
                  <LogoutIcon className="dropdown-icon text-danger me-2" /> Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
{/*
				      <Nav.Item onClick={()=> navigate(myRoutes.Students.path, {replace: true})}>
				        <Nav.Link eventKey="home" href="#" className=" mb-sm-3 mb-md-2 py-2" style={{fontSize:"0.85rem"}}>
					           <AcademicCapIcon className="icon icon-xxs me-1" style={{strokeWidth:"1.5"}} /> Students 
									STUDENTS
				        </Nav.Link>
				      </Nav.Item>   
*/}       	
{/*
					      <Nav.Item onClick={()=> navigate(myRoutes.Share.path, {replace: true})}>
				        <Nav.Link eventKey="home" href="#" className=" mb-sm-3 mb-md-2 py-2" style={{fontSize:"0.85rem"}}>
					           <AcademicCapIcon className="icon icon-xxs me-1" style={{strokeWidth:"1.5"}} /> Share  
									SHARE
				        </Nav.Link>
				      </Nav.Item>          	
*/}
        		</Nav>
          </div>
					
					{(currentRoute.pathname === '/dashboard/dashboardoperator.js' || currentRoute.pathname === '/') ? 
		        (
		        	<div className="d-flex justify-content-center align-items-center" style={{backgroundColor:"#d1d5db"}}>
								<LocationDropDownWidget />
			        </div>
			      ) : (
			      	<div></div>
			      )
					}

          <Nav className="align-items-center">
 				      <Nav.Item onClick={()=> navigate(myRoutes.DashboardOperator.path, {replace: true})}>
				        <Nav.Link eventKey="home" href="#" className="mb-md-2 py-0" >
				           <ChartSquareBarIcon className="icon" style={{strokeWidth:"1.3", height:"1.6rem"}} /><span style={{fontSize:'0.8rem'}}></span>
				        </Nav.Link>
				      </Nav.Item>          	
         
				      <Nav.Item onClick={()=> navigate(myRoutes.Share.path, {replace: true})}>
				        <Nav.Link eventKey="share" href="#" className="mb-md-2 px-0">
				           <PaperAirplaneIcon className="icon icon-xxs me-1" style={{strokeWidth:"1.3", height:"1.3rem", rotate: "65deg", marginTop: "-5px"}} />
				        </Nav.Link>
				      </Nav.Item>          	
          	
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} className="mb-md-2 py-0 mx-n1">
              <Dropdown.Toggle as={Nav.Link} className={`py-1 icon-xxs notification-bell ${bellIconClasses}`}>
                <MailIcon className=" icon icon-xxs me-1" style={{strokeWidth:"1.3", height:"1.5rem"}}/>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}
{/*
                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
*/}
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <SignOutConfirm />
          </Nav>
        </div>
{/*
        <div className="d-flex justify-content-center w-100" style={{backgroundColor:"lightgray"}}>
					<LocationPillsWidget />
        </div>
*/}
      </Container>
    </Navbar>
    <ResetPasswordModal />
    </>
    
  );
};
